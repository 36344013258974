import { HttpErrorResponse } from '@angular/common/http';
import { ApplicationRef, ChangeDetectorRef, Component, ElementRef, OnInit, Sanitizer, ViewChild } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { UserService } from '../shared/user.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
// import { ChartDataSets, ChartType, RadialChartOptions } from 'chart.js';
// import { Label, Color } from 'ng2-charts';

// let access_token = null;

declare var OrboSmartCapture: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
    access_token: string = "";
    user_id = null;
    results = false;
    loader = false;
    reader: FileReader = new FileReader;
    previewImage = new Image();
    url: string | SafeUrl = './../assets/images/skin_analysis.gif';
    data: string;
    imageUrl: string;
    imgFile = undefined;
    outputImages: any = {};
    outputData: any = {};
    images: any = {};
    topPriorities: any = {};
    routineInfo: any = {};
    isImage: boolean;
    showButton = true;
    isSkinType: boolean = false;
    isAge: boolean = false;
    skinOptions = [
        { id: 0, value: 'ns', name: 'Normal' },
        { id: 1, value: 'ds', name: 'Dry' },
        { id: 2, value: 'os', name: 'Oily' }
    ];
    ageOptions = [
        { id: 0, value: 'lt30', name: '<30' },
        { id: 1, value: 'gt30', name: '>30' }
    ];
    selectedSkinType;
    selectedAge;


    // toggle webcam on/off
    public showWebcam = false;
    public allowCameraSwitch = true;
    public multipleWebcamsAvailable = false;
    public deviceId: string;
    public videoOptions: MediaTrackConstraints = {
        width: { ideal: 500 },
        height: { ideal: 500 }
    };

    public mirrorImage: string = "always";

    public errors: WebcamInitError[] = [];

    // latest snapshot
    public webcamImage: WebcamImage = <any>'./../assets/skin_analysis.gif';

    @ViewChild('smartCaptureContainer')
    public smartCaptureContainer: ElementRef<HTMLDivElement>;

    // webcam snapshot trigger
    private trigger: Subject<void> = new Subject<void>();
    // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
    private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

    public data0 = {}
    public datas1 = []
    public datas2 = []
    public datas3 = []
    public datas4 = []
    public dataImage = {}

    public chartRadiusHealth = 30;
    public titleFontSizeHealth = '20'
    public outerStrokeWidthHealth = 5;
    public innerStrokeWidthHealth = 3;
    public spaceHealth = -4;
    public chartRadius = 20;
    public titleFontSize = '15';
    public unitsFontSize = '10';
    public outerStrokeWidth = 4;
    public innerStrokeWidth = 2;
    public space = -3;

    constructor(private _userService: UserService,
        private route: ActivatedRoute,
        private _router: Router,
        private _toastr: ToastrService,
        private sanitizer: DomSanitizer) { }

    ngOnInit() {
        // this.user_id = localStorage.getItem('userId');
        // access_token = localStorage.getItem('userToken');
        // if (localStorage.getItem('userToken') == null) {
        //     this._router.navigate(['/login']);
        // }

        this.route.queryParamMap.pipe(take(1)).subscribe((params) => {
            this.access_token = params.get("access_token");
        });

        WebcamUtil.getAvailableVideoInputs()
            .then((mediaDevices: MediaDeviceInfo[]) => {
                this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
            });

        const windowSize = window.matchMedia("(max-width: 768px)");
        this.chartRadiusHealth = (windowSize.matches) ? 20 : 30;
        this.titleFontSizeHealth = (windowSize.matches) ? '15' : '20';
        this.outerStrokeWidthHealth = (windowSize.matches) ? 3 : 5;
        this.innerStrokeWidthHealth = (windowSize.matches) ? 2 : 3;
        this.spaceHealth = (windowSize.matches) ? -2.5 : -4;
        this.chartRadius = (windowSize.matches) ? 9.5 : 20;
        this.titleFontSize = (windowSize.matches) ? '8' : '15';
        this.unitsFontSize = (windowSize.matches) ? '6' : '10';
        this.outerStrokeWidth = (windowSize.matches) ? 2 : 4;
        this.innerStrokeWidth = (windowSize.matches) ? 1 : 2;
        this.space = (windowSize.matches) ? -1.5 : -3;

        this.selectedSkinType = this.skinOptions[0];
        this.selectedAge = this.ageOptions[0];
    }

    getToken(event: any) {
        console.log('From get: ', event.target.value);
        this.access_token = event.target.value;
    }

    setToken() {
        console.log('From set: ', this.access_token);
        // document.cookie = 'token=' + this.token + '; domain=' + CONSTANTS.DOMAIN;
        // document.cookie = 'token=' + this.access_token;
        this._toastr.success('Token has been set!');
    }

    async startWebcam() {
        this.url = "";
        this.showWebcam = true;
        const picBlob = await OrboSmartCapture.capturePic(this.smartCaptureContainer.nativeElement);
        this.handleImage(picBlob);
        this.showWebcam = false;
    }

    public triggerSnapshot(): void {
        this.showWebcam = false;
        this.trigger.next();
    }

    public toggleWebcam(): void {
        this.showWebcam = !this.showWebcam;
    }

    public handleInitError(error: WebcamInitError): void {
        this.errors.push(error);
    }

    public showNextWebcam(directionOrDeviceId: boolean | string): void {
        // true => move forward through devices
        // false => move backwards through devices
        // string => move to device with given deviceId
        this.nextWebcam.next(directionOrDeviceId);
    }

    private flipAndCrop(src: any): Promise<{ dataURL: string, base64: string }> {
        return new Promise<{ dataURL: string, base64: string }>((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                // Calculate dimensions for cropping to 1:1 aspect ratio
                let size, offsetX, offsetY;
                if (img.width > img.height) {
                    size = img.height;
                    offsetX = (img.width - size) / 2;
                    offsetY = 0;
                } else {
                    size = img.width;
                    offsetX = 0;
                    offsetY = (img.height - size) / 2;
                }

                // Flip the image
                canvas.width = size;
                canvas.height = size;
                ctx.scale(-1, 1);
                ctx.drawImage(img, -offsetX - size, -offsetY, img.width, img.height);

                // Get the base64 representation
                const flippedDataUrl = canvas.toDataURL();
                const base64 = flippedDataUrl.split(',')[1]; // Extract base64 part
                resolve({ dataURL: flippedDataUrl, base64: base64 }); // Resolve with data URL and base64
            };
            img.onerror = (error) => reject(error);
            img.src = src;
        });
    }

    public handleImage(capturedPic): void {
        // this.flipAndCrop(webcamImage.imageAsDataUrl)
        //     .then((result: { dataURL: string, base64: string }) => {
        // const { dataURL, base64 } = result;
        // // Now you have both data URL and base64 of the flipped and cropped image
        this.url = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(capturedPic)); // Set the data URL

        // // Naming the image
        const date = new Date().valueOf();
        let text = '';
        const possibleText = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            text += possibleText.charAt(Math.floor(Math.random() * possibleText.length));
        }
        // Replace extension according to your media type
        const imageName = date + '.' + text + '.jpeg';
        // // call method that creates a blob from dataUri
        // const imageBlob = this.dataURItoBlob(base64);

        this.imgFile = new File([capturedPic], imageName, { type: 'image/jpeg' });
        // console.log(this.imgFile)
        this.isImage = true;
        this.selectedSkinType = this.skinOptions[0];
        this.selectedAge = this.ageOptions[0];
        // this.changeDetectorRef.detectChanges();

        // })
        // .catch((error) => {
        //     console.error('Error occurred while flipping and cropping image:', error);
        // });
    }


    public cameraWasSwitched(deviceId: string): void {
        // console.log('active device: ' + deviceId);
        this.deviceId = deviceId;
    }

    public get triggerObservable(): Observable<void> {
        return this.trigger.asObservable();
    }

    public get nextWebcamObservable(): Observable<boolean | string> {
        return this.nextWebcam.asObservable();
    }


    dataURItoBlob(dataURI) {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: 'image/jpeg' });
        return blob;
    }

    changeImage(event) {
        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader();

            reader.readAsDataURL(event.target.files[0]); // read file as data url
            this.imgFile = event.target.files[0];

            reader.onload = (event) => { // called once readAsDataURL is completed
                this.url = (<FileReader>event.target).result as string;
            };
            this.isImage = true;
            this.selectedSkinType = this.skinOptions[0];
            this.selectedAge = this.ageOptions[0];
        }
    }

    // selectSkinType(e) {
    //     this.isSkinType = true;
    //     this.selectedSkinType = this.skinOptions[e.target.value];
    // }

    // selectAge(e) {
    //     this.isAge = true;
    //     this.selectedAge = this.ageOptions[e.target.value];
    // }

    applyEffect() {
        this.loader = true;
        if (!this.imgFile) {
            this.results = false;
            this.loader = false;
            this._toastr.error('Upload an Image file', 'Uh Oh!');
        } else {
            this._userService.analyzeSkin(this.imgFile, this.selectedSkinType.value, this.selectedAge.value, this.access_token).subscribe((data) => {
                this.loader = false;
                this.results = true;
                this.isImage = false;
                this.outputImages = data['data']['outputImages'];
                this.outputData = data['data']['scores'];
                this.images = data['data']['outputImages'];


                console.log("Solomomo demo");



                this.datas3 = [
                    { 'score': this.outputData.hydration, 'label': 'Hydration Score', image: this.images.hydration },
                    { 'score': this.outputData.texture, 'label': 'Skin Texture', image: this.images.texture },
                    { 'score': this.outputData.oxygen, 'label': 'Oxygen', image: this.images.oxygen },
                    { 'score': this.outputData.uneven_skin, 'label': 'Uneven Skin Tone', image: this.images.uneven_skin },
                    { 'score': this.outputData.dark_spots, 'label': 'Dark Spots', image: this.images.dark_spots },
                    { 'score': this.outputData.face_wrinkles, 'label': 'Face Wrinkle', image: this.images.face_wrinkles },
                    { 'score': this.outputData.pigmentation, 'label': 'Pigmentation', image: this.images.pigmentation },
                    { 'score': this.outputData.shine, 'label': 'Shine', image: this.images.shine },
                ];

                this.datas4 = [
                    { 'score': this.outputData.skin_dullness, 'label': 'Dullness', image: this.images.skin_dullness },
                    { 'score': this.outputData.firmness, 'label': 'Firmness', image: this.images.firmness },
                    { 'score': this.outputData.smoothness, 'label': 'Smoothness', image: this.images.smoothness },
                    { 'score': this.outputData.dark_circle, 'label': 'Dark Circle', image: this.images.dark_circle },
                    { 'score': this.outputData.acne, 'label': 'Acne', image: this.images.acne },
                    { 'score': this.outputData.eye_wrinkles, 'label': 'Eye Wrinkle', image: this.images.eye_wrinkles },
                    { 'score': this.outputData.crows_feet, 'label': 'Crows Feet', image: this.images.crows_feet },
                    { 'score': this.outputData.redness, 'label': 'Redness', image: this.images.redness }
                    // { 'score': this.outputData.lips_texture, 'label': 'Lips Texture', image: this.images.lips_texture },
                    // { 'score': this.outputData.black_heads, 'label': 'Black Heads', image: this.images.black_heads }
                    // { 'score': 0, 'label': 'Combined Defects', image: this.images.combined_defects }
                ]

                // this.dataImage = { 'score': this.outputData.redness, 'label': 'Redness', image: this.images.redness } // for single score
            },
                (err: HttpErrorResponse) => {
                    this._toastr.error(err.error.error.message, err.error.error.code);
                    this.loader = false;
                    this.results = false;
                    this.imgFile = undefined;
                    this.isImage = false;
                });
        }
    }

    @ViewChild("annotatedFaceDlg")
    annotatedFaceDlg: ElementRef<HTMLDialogElement>;

    annotatedFaceImgUrl = "";
    conernName = "";
    showAnnotatedFace({ image, label }) {
        if (image) {
            this.conernName = label;
            this.annotatedFaceImgUrl = image;
            this.annotatedFaceDlg.nativeElement.showModal();
        }
    }

    closeAnnotatedDialog() {
        this.annotatedFaceDlg.nativeElement.close();
        this.annotatedFaceImgUrl = "";
        this.conernName = "";
    }

    // Logout() {
    //     localStorage.clear();
    //     this._userService.logout(access_token).subscribe((data: any) => {
    //         this._router.navigate(['/login']);
    //     });
    // }

}
